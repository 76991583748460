import React from 'react';
import Home from './pages/Home'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Nabber from './components/Nabber'
// import Create from './pages/Create'
import TableOfContents from './pages/TableOfContents'
import BlogPost from './pages/BlogPost'

function App() {
  return (
    <Router>
      <Nabber />
      <Route exact path="/" component={Home} />
      <Route exact path="/publications" component={TableOfContents} />
      <Route exact path="/publication/:publicationId" component={BlogPost} />
      {/* <Route exact path="/create" component={Create} /> */}
    </Router>
  );
}

export default App;
