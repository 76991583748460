import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import { Nav } from 'react-bootstrap'


export default function Nabber() {
  return (
    <Navbar bg="dark" variant="dark" >
      {/* <Navbar.Brand>
        blog.KelleyMichelle
      </Navbar.Brand> */}
      <Nav.Link href="/" style={{color: 'white'}}>
        Home
      </Nav.Link>
      <Nav.Link href="/publications" style={{color: 'white'}}>
        Publications
      </Nav.Link>
      <Nav.Link target="_blank" rel="noopener noreferrer" href="http://kelleymichelle.com" style={{color: 'white'}}>
        kelleymichelle.com
      </Nav.Link>
    </Navbar>
  )
}