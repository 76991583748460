import React from 'react'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom'
import star from '../images/star-billet.png'
import LightSpeed from 'react-reveal/LightSpeed'
import moment from 'moment'

export default function PostCard(props) {
  const pubDate = moment(props.post.pubDate)
  // console.log(pubDate)
  const title = props.post.title
  const blogLink = `/publication/${props.post.title}`
  // const thumbnail = props.post.thumbnail
  
  return (
    <LightSpeed right delay={props.delayTime}>
    <Link to={{ pathname: blogLink, state: {post: props.post}}} >
      <Card id="post-card" style={{color: 'white', textDecoration: 'none', marginLeft: '3%', marginRight: '3%', backgroundColor: 'transparent', border: 'none'}} className="d-flex flex-row align-content-center justify-content-around shake-trigger">
        
        <img className="shake" src={star} alt="star"style={{width: '3em', height: '3em', marginLeft: '15px', marginTop: '15px'}}/>
        
        <Card.Body>
          
          <Card.Title style={{fontFamily: 'Abril Fatface, cursive'}}>
            {title}
          </Card.Title>
          <Card.Subtitle>
            {pubDate.subtract(6, 'hours').format('LLLL')}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </Link>
    </LightSpeed>
  )
}