import React from 'react'

export default class BlogPost extends React.Component {

  componentDidMount() {

    document.getElementById('blog-content').innerHTML = this.props.location.state.post.content

  }

  render() {
    const post = this.props.location.state.post
    
    return (
      
      <div id="blog-post">
        <center>
          <div id="title-type" style={{fontSize: '3.5em'}}>
            {post.title}
          </div>

          <div id="blog-content" classname="d-flex flex-column" style={{margin: '4%', color: 'white'}}>
            {/* { post.content.toHtml } */}
          </div>
        </center>

      </div>
    )
  }
}