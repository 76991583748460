import React from 'react'
import Pulse from 'react-reveal/Pulse'
import SocialIcons from '../components/SocialIcons'

export default function Home() {
  return (
    <div id="home-page">
      <Pulse>
        <div id="home-title" style={{fontSize: '4em', color: 'white'}}>
          <div>Kelley Michelle</div>
          {/* <br/> */}
          <div style={{fontSize: 'smaller'}}>Blog</div>
          <SocialIcons/>
        </div>
      </Pulse>
    </div>
  )
}