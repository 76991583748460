import React from 'react'
import axios from 'axios'
// import BlogPost from './BlogPost'
import PostCard from '../components/PostCard'


export default class TableOfContents extends React.Component {
  
  state = {
    publications: []
  }

  componentDidMount() {
    axios.get('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40kmchaplain&api_key=zdywqrodkvio5qgxwmaszfbc9sxudpefdzwiifkp')
      .then(res => this.setState({ publications: res.data.items }))
      .catch(err => console.log(err))
  }
  
  render() {
    return (
      <div className="publications">

        <div id="title-type" className="d-flex justify-content-center">
          <center>
            <div style={{fontSize: '3em'}}>Musings of a Techie</div>
          </center>
        </div>

        <div>
          { this.state.publications.map((p, i) => p.categories.length > 0 ? <PostCard post={p} key={i} delayTime={i*250} /> : null )}
        </div>
      </div>
    )
  }
}