import React from 'react'
import { SocialMediaIconsReact } from 'social-media-icons-react'

export default function SocialIcons() {
  return (
    <div className="d-flex justify-content-around" style={{width: '175px'}}>
      <SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="inset" icon="github" iconColor="rgba(7,28,53,1)" backgroundColor="rgba(52,219,159,1)" iconSize="5" roundness="17%" url="https://github.com/kelleymichelle" size="50" />
      <SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="inset" icon="twitter" iconColor="rgba(7,28,53,1)" backgroundColor="rgba(52,219,159,1)" iconSize="5" roundness="17%" url="https://twitter.com/kelley_chaplain" size="50" />
      <SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="inset" icon="linkedin" iconColor="rgba(7,28,53,1)" backgroundColor="rgba(52,219,159,1)" iconSize="5" roundness="17%" url="https://www.linkedin.com/in/kelley-chaplain/" size="50" />

    </div>
  )
}